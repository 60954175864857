@use './common/util/mixins';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&display=swap');
@import '~bootstrap/scss/bootstrap';
@import './common/util/variables';

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-family-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color-light;
  line-height: 2;

  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      color: inherit;
    }
  }

  p,
  em {
    color: $p-color-dark;
  }

  .modal-content {
    background-color: $color-secondary-2;
  }

  @include mixins.scrollbar($color-primary-1);
}

body::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='200' height='200'><filter id='noise'><feTurbulence type='fractalNoise' baseFrequency='0.8' numOctaves='4' stitchTiles='stitch'/></filter><rect width='100%' height='100%' filter='url(%23noise)'/></svg>") repeat;
  opacity: 0.1;
}

::selection {
  background: $color-primary-1;
  color: $text-color-light;
}

::-moz-selection {
  background: $color-primary-1;
  color: $text-color-light;
}
